import axios from 'axios';

/**
 *  所有共用页面中的接口
 * **/


/**
 * 获取新闻资讯
 * **/
export function getNewsData (pageParams) {
  return axios({
    url: `/website/web/page`,
    method: "get",
    params: {
      status: 1,
      ...pageParams
    }
  });
}

/**
 * 获取新闻资讯详情
 * **/
export function getNewsDataDetails (id) {
  return axios({
    url: `/website/web/${id}`,
    method: "get",
  });
}
