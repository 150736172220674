<template>
 <div class="my-banner-box">
   <div  style="height: 6.67rem;overflow: hidden">
     <template v-for="(item, index) in carouselList" >
       <div class="my-banner-box-item" :key="index" v-if="initialIndex == index" :class="initialIndex == index ? 'loaded' : ''">
         <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}" >
           <div class="container">
             <h2>{{item.txt}}</h2>
             <h1><span>{{item.txt2}}</span>{{item.txt3}}</h1>
             <el-button @click="jumpUrl(item)">了解更多&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
           </div>
         </div>
       </div>
     </template>
   </div>
   <div class="home-banner-indicator flex align-center">
     <i @click="leftPrev" class="el-icon-arrow-left"></i>
     <ul class="flex align-center">
       <li @click="changeCarousel(index_)" v-for="(item_, index_) in carouselList" :class="initialIndex == index_ ? 'active' : ''" :key="index_"></li>
     </ul>
     <i @click="rightNext" class="el-icon-arrow-right"></i>
   </div>

<!--   <el-carousel :autoplay="true" ref="carousel" @change="changeCarousel" :interval="3000" arrow="always" height="6.67rem" :initial-index="initialIndex">
     <el-carousel-item v-for="(item, index) in carouselList" :key="index">
       <div class="home-banner-img" :style="{backgroundImage: 'url('+ item.img + ')'}">
         &lt;!&ndash;<div class="home-banner-img gradient-bg" :style="{ background: `linear-gradient(to right, #${randomColor()}, #${randomColor()})` }">&ndash;&gt;
         <div class="container">
           <h2>{{item.txt}}</h2>
           <h1><span>{{item.txt2}}</span>{{item.txt3}}</h1>
           <el-button @click="jumpUrl(item)">了解更多&nbsp;  <i class="el-icon-arrow-right"></i></el-button>
         </div>
       </div>
     </el-carousel-item>
   </el-carousel>
   &lt;!&ndash; 指示器 &ndash;&gt;
   <div class="home-banner-indicator flex align-center">
     <i @click="leftPrev" class="el-icon-arrow-left"></i>
     <ul class="flex align-center">
       <li @click="changeCarousel(index_)" v-for="(item_, index_) in carouselList" :class="initialIndex == index_ ? 'active' : ''" :key="index_"></li>
     </ul>
     <i @click="rightNext" class="el-icon-arrow-right"></i>
   </div>-->
 </div>
</template>

<script>

export default {
  name: 'Menu',
  components: {
  },
  props: {

  },
  mixins: [],
  data () {
    return {
      carouselList: [
        { img: require('@/assets/images/home.png'), txt: '用心做，好网络', txt2: '园区网络运营', txt3: '服务提供商', routerName: 'plan4'},
        { img: require('@/assets/images/banner.jpg'), txt: '全业务场景的服务', txt2: '高校数字化', txt3: '管理解决方案', routerName: 'plan3'},
        { img: require('@/assets/images/banner11.jpg'), txt: '全周期的安全保障', txt2: '网络安全运营', txt3: '服务提供商', routerName: 'plan2'},
      ],
      initialIndex: 0
    }
  },
  computed: {

  },
  watch: {

  },
  mounted () {
  },
  beforeDestroy() {
  },
  created () {
    setInterval(() => {
      this.initialIndex++;
      if (this.initialIndex == 3) {
        this.initialIndex = 0
      }

    }, 6000)
  },
  methods: {
    /* 轮播切换 */
    changeCarousel (val) {
      this.initialIndex = val;
    },
    /* 切换上一个 */
    leftPrev () {
      if (this.initialIndex == 0) {
        this.initialIndex = 3
      }
      this.initialIndex--;
    },
    /* 切换下一个 */
    rightNext () {
      this.initialIndex++;
      if (this.initialIndex == 3) {
        this.initialIndex = 0
      }
    },
    /* 跳转页面 */
    jumpUrl (item) {
      if(item.routerName) {
        this.$router.push({name: item.routerName})
      }
    },
  }
};
</script>
<style scoped  lang="scss">
.my-banner-box{
  .my-banner-box-item{
    height: 100%;
    /*opacity: 0;
    transition: opacity 5s ease-in;*/
  }
  .my-banner-box-item.loaded {
    //opacity: 1;
  }
}
/*.my-banner-box{
  .home-banner-img{
    display: none;
  }
}*/
</style>
