<template>
 <div id="home" class="home" v-title data-title="河南安冉云网络科技有限公司">
  <!-- 页面导航 -->
  <menu-components type="home"/>

  <!-- 页面主体 -->
  <main class="main">

   <!--轮播-->
   <section class="home-banner">
     <banner-my/>
   </section>

   <!--我们的服务数据-->
   <section class="home-s-1">
    <div class="container scrollflow  -slide-top">
     <div class="flex align-center justify-between home-s-t">
      <h2>我们的服务数据</h2>
      <div @click="jumpUrlAbout" class="flex align-center" style="width: auto;cursor: pointer"><span></span>查看更多</div>
     </div>
     <p>截止到今日</p>

    </div>
    <div class="flex serve_list_box home-s-1-an">
     <div v-for="(item, index) in serveList" :key="index">
      <div class="flex justify-center">
       <h2>
        <animate-number
            class="scrollflow cph-font"
            from="1"
            :to="item.num"
            duration="2000"
            easing="easeOutQuad"
            :formatter="formatter"
        ></animate-number>
       </h2>
       <span>{{item.st}}</span>
      </div>
      <p>{{item.name}}</p>
     </div>
    </div>
   </section>

  <!-- 核心业务-->
   <section class="home-s-2">
     <div>
      <div class="container">
       <h1>核心业务</h1>
       <p>聚焦高校场景，为高校提供高品质的网络运营及信息化服务</p>
       <div @click="$router.push({name: 'plan4'})" style="cursor: pointer"><img src="@/assets/images/42.fw.png" alt="">了解更多</div>
      </div>
      <ul class="flex">
       <li v-for="(item, index) in homeS2List" :key="index" :style="{width: item.width}" @click="jumpUrl(item)">
        <div :style="{backgroundImage: 'url('+ item.bgImg + ')'}">
         <h2>{{item.name}}</h2>
         <p>{{item.txt}}</p>
         <img @click="jumpUrl(item)" style="cursor: pointer" src="@/assets/images/48.fw.png" alt="">
        </div>
       </li>
      </ul>
     </div>
   </section>

  <!-- 我们为为高校数字化转型 -->
   <section class="home-s-3">
     <div class="container scrollflow -slide-top">
       <div class="home-s-t flex align-center justify-between">
         <div>
           <h1>为高校数智化转型赋能</h1>
           <!--<h2>提供专属方案</h2>-->
         </div>
        <div @click="$router.push({name: 'product4'})" class="flex align-center" style="width: auto;cursor: pointer"><span></span>查看更多</div>
       </div>
      <el-row :gutter="40"  class="home-s-3-c" style="margin-top:.4rem;margin-bottom:.5rem;">
       <el-col v-for="(item, index) in homeS3List" :key="index" :span="5">
        <div class="home-s-3-c-1"
             :class="homeS3Index == index ? 'active' : ''"
             @mouseenter="jumpUrlHomeS3(item, index)"
             @mouseleave="jumpUrlHomeS3(item, index)"
        >
         <img :src="homeS3Index == index ? item.imgActive : item.img" alt="index">
         <div>{{item.name}}</div>
         <p>{{item.txt}}</p>
        </div>
       </el-col>
      </el-row>
      <el-row :gutter="80"  class="home-s-3-b">
       <el-col :sm="24" :md="11">
        <img class="home-s-3-b-l scrollflow -slide-top " :src="homeS3IndexDetails.img1" alt="">
       </el-col>
       <el-col :sm="24" :md="13">
        <div class="home-s-3-b-r scrollflow -slide-top ">
         <h2>{{homeS3IndexDetails.title}}</h2>
         <span></span>
         <p style="margin-bottom:.1rem;">{{homeS3IndexDetails.txt}}</p>
         <p :style="{marginTop: homeS3IndexDetails.title == '智能运维平台' ? '0' : ''}">{{homeS3IndexDetails.txt1}}</p>
         <div @click="jumpUrl(homeS3IndexDetails)"
              :style="{marginTop: homeS3IndexDetails.title == '智能运维平台' ? '-.15rem' : '.55rem'}">了解更多 <img src="@/assets/images/42.fw.png" alt=""></div>
        </div>
       </el-col>
      </el-row>
     </div>
   </section>

  <!-- 与您分享 -->
   <section class="home-s-4">
    <div class="container scrollflow -slide-top">
     <div class="home-s-t flex align-center justify-between">
      <div>
       <h1>与您分享</h1>
       <h2>公司实时动态</h2>
      </div>
      <div @click="$router.push({name: 'news'})" class="flex align-center" style="width: auto;cursor: pointer"><span></span>查看更多</div>
     </div>
     <el-row :gutter="40"  class="home-s-4-l">
      <el-col v-for="(item, index) in homeS4List" class="home-s-4-l-b"  :key="index" :xs="12" :md="8">
       <div class="home-s-4-i scrollflow -slide-top" style="padding-bottom: 0;" @click="jumpDetails(item)">
        <p class="cph-font">{{item.createTime}}</p>
        <div class="line_tow">{{item.title}}</div>
        <img :src="item.bgUrl" alt="">
       </div>
      </el-col>
     </el-row>
    </div>
   </section>

  <!-- 运营案例 -->
   <section class="home-s-5">
    <div class="container scrollflow -slide-top">
     <div class="home-s-t flex align-center justify-between">
      <div>
       <h1>服务案例</h1>
      </div>
     </div>
     <img class="home-s-5-i" src="@/assets/images/15.jpg" alt="">
    </div>
   </section>
  </main>

  <div>


  </div>

  <!-- 页面底部 -->
  <footer-components/>
 </div>
</template>

<script>
import MenuComponents from "@/components/Menu/index.vue";
import FooterComponents from "@/components/Footer/index.vue";
import Public from "@/assets/js/public";
import BannerMy from '@/components/index.vue'
import {getNewsData} from '@/api/index.js'

export default {
  name: 'Home',
  components: {
   MenuComponents,
   FooterComponents,
   BannerMy
  },
  mixins: [],
  data () {
    return {
     images: [
      require('@/assets/images/home.png'),
      require('@/assets/images/banner.jpg'),
      require('@/assets/images/banner11.jpg'),
     ],
     serveList: [
      {num: 60, st: '万+', name: '校园网用户覆盖'},
      {num: 11, st: '万+', name: '网元数量'},
      {num: 520, st: 'G', name: '互联网接入专线带宽'},
      {num: 2.5, st: '亿', name: '管理网络资产规模'},
      {num: 80, st: '余项', name: '获得专利及软件著作'},
     ],
     // 核心业务
     homeS2List: [
      {name: '网络运营服务',bgImg: require('@/assets/images/12.jpg'), txt: '大型园区网络运营服务业务，MSP (管理服务提供商) 形态', routerName: 'plan4', width: '31%'},
      {name: '软件应用服务',bgImg: require('@/assets/images/11.jpg'), txt: '为企事业单位提供定制化软件服务', routerName: 'product2', width: '23%'},
      {name: '网络安全服务',bgImg: require('@/assets/images/13.jpg'), txt: '为用户提供的安全风险管理和安全运营管理', routerName: 'plan2', width: '23%'},
      {name: '数字生态使能',bgImg: require('@/assets/images/114.jpg'), txt: '通过对网络及应用产生的大量真实数据服务于大学生', routerName: 'cooperation', width: '23%'},
     ],
     // 专属方案
     homeS3List: [
      {name: '招生场景', img: require('@/assets/images/50.fw.png'), imgActive: require('@/assets/images/49.fw.png'), txt: 'Recruit Students', routerName: 'product4'},
      {name: '迎新场景', img: require('@/assets/images/37.fw.png'), imgActive: require('@/assets/images/43.fw.png'), txt: 'Freshman Enrollment', routerName: 'product3'},
      {name: '在校生管理', img: require('@/assets/images/38.fw.png'), imgActive: require('@/assets/images/44.fw.png'), txt: 'Student Management', routerName: 'product2'},
      {name: '毕业生管理', img: require('@/assets/images/39.fw.png'), imgActive: require('@/assets/images/45.fw.png'), txt: 'Leaving School', routerName: 'product1'},
      {name: '校友系统', img: require('@/assets/images/40.fw.png'), imgActive: require('@/assets/images/46.fw.png'), txt: 'Alumni System', routerName: 'product5'},
     ],
     // 当前专属方案下标
     homeS3Index: 0,
     homeS3IndexDetails: Public.product4(),
     // 与您分享
     homeS4List: [],
     pageParams: {
      page: 1,
      limit: 3,
     },
    };
  },
  watch: {
  },
  mounted () {},
  created () {
   this.getNewsData();
  },
  methods: {
   /* 获取数据 */
   getNewsData () {
    getNewsData(this.pageParams).then(res => {
     if (res.data.code == 0) {
      this.homeS4List = res.data.data.length > 3 ? res.data.data.slice(0, 3) : res.data.data;
     } else {
      this.$message.error(res.msg);
     }
    })
   },
   // 鼠标进入 进出
   handleMouse(index) {
    this.homeS3Index = index;
   },
   formatter: function (num) {
    return Public.formatterNum(num)
   },
   /* 专属方案页面跳转 */
   jumpUrlHomeS3(item, index) {
    this.homeS3Index = index;
    let obj = Public[item.routerName]();
    this.homeS3IndexDetails = obj;
   },
   /* 跳转页面 */
   jumpUrl (item) {
    if(item.routerName) {
     this.$router.push({name: item.routerName})
    }
   },
   /* 跳转关于我们 */
   jumpUrlAbout () {
    this.$router.push({name: 'about'})
   },
   /* 跳转详情 */
   jumpDetails (item) {
    this.$router.push({name: 'newsDetails', query: {id: item.id}})
   }
  }
};
</script>
<style scoped  lang="scss">

</style>
<style>
.el-col-5 {
 width: 20%;
}

.gradient-bg {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 100%;
 color: white;
 text-align: center;
}
</style>
